* { 
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #61c0ff;
  animation: animateBg 15s linear infinite;
  height: 100%;
}

@keyframes animateBg {
  0%
  {
    background-color: #61c0ff;
  }
  50%
  {
    background-color: #23d5ab;
  }
  100%
  {
    background-color: #61c0ff;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
